import React, { createContext, PropsWithChildren, useState } from 'react';

export enum LOADING_KEYS {
	LOGIN = 'LOGIN',
	GET_DEVICES = 'GET_DEVICES',
	POST_DEVICE = 'POST_DEVICE',
	GET_DEVICE = 'GET_DEVICE',
	UPDATE_DEVICE = 'UPDATE_DEVICE',
	GET_MEASUREMENTS = 'GET_MEASUREMENTS',
	GET_TRANSPORTS = 'GET_TRANSPORTS',
	POST_TRANSPORT = 'POST_TRANSPORT',
	GET_TRANSPORT = 'GET_TRANSPORT',
	UPDATE_TRANSPORT = 'UPDATE_TRANSPORT',
}

type LoadingType = {
	[LOADING_KEYS.LOGIN]: boolean;
	[LOADING_KEYS.GET_DEVICES]: boolean;
	[LOADING_KEYS.POST_DEVICE]: boolean;
	[LOADING_KEYS.GET_DEVICE]: boolean;
	[LOADING_KEYS.UPDATE_DEVICE]: boolean;
	[LOADING_KEYS.GET_MEASUREMENTS]: boolean;
	[LOADING_KEYS.GET_TRANSPORTS]: boolean;
	[LOADING_KEYS.POST_TRANSPORT]: boolean;
	[LOADING_KEYS.GET_TRANSPORT]: boolean;
	[LOADING_KEYS.UPDATE_TRANSPORT]: boolean;
};

export interface ILoadingContext {
	isLoading: LoadingType;
	setLoading: (key: keyof typeof LOADING_KEYS, value: boolean) => void;
}

const LoadingContext = createContext<ILoadingContext | null>(null);

export const LoadingProvider: React.FC<PropsWithChildren> = ({ children }) => {
	const [isLoading, setIsLoading] = useState({
		[LOADING_KEYS.LOGIN]: false,
		[LOADING_KEYS.GET_DEVICES]: true,
		[LOADING_KEYS.POST_DEVICE]: false,
		[LOADING_KEYS.GET_DEVICE]: true,
		[LOADING_KEYS.UPDATE_DEVICE]: false,
		[LOADING_KEYS.GET_MEASUREMENTS]: false,
		[LOADING_KEYS.GET_TRANSPORTS]: true,
		[LOADING_KEYS.POST_TRANSPORT]: false,
		[LOADING_KEYS.GET_TRANSPORT]: true,
		[LOADING_KEYS.UPDATE_TRANSPORT]: false,
	});

	const setLoading = (key: keyof typeof LOADING_KEYS, value: boolean) => {
		setIsLoading((prevState) => {
			const copyOfPrevState = { ...prevState };

			copyOfPrevState[key] = value;

			return copyOfPrevState;
		});
	};

	return (
		<LoadingContext.Provider value={{ isLoading, setLoading }}>
			{children}
		</LoadingContext.Provider>
	);
};

export default LoadingContext;
