import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { PrivateRoute } from 'components/organisms';
import { CircularProgress } from '@mui/material';
import { PATHS } from 'shared/routerPaths';

const RootView = lazy(() => import('packages/AuthView'));
const DevicesView = lazy(() => import('packages/DevicesView'));
const TransportsView = lazy(() => import('packages/TransportsView'));

export const MainView: React.FC = () => {
	return (
		<Suspense fallback={<CircularProgress />}>
			<Router>
				<Routes>
					<Route path='*' element={<RootView />} />
					<Route
						path={`${PATHS.TRANSPORTS.INDEX}/*`}
						element={
							<PrivateRoute>
								<TransportsView />
							</PrivateRoute>
						}
					/>
					<Route
						path={`${PATHS.DEVICES.INDEX}/*`}
						element={
							<PrivateRoute>
								<DevicesView />
							</PrivateRoute>
						}
					/>
				</Routes>
			</Router>
		</Suspense>
	);
};
