import React from 'react';
import ReactDOM from 'react-dom/client';
import { MainView } from 'packages/MainView';
import GlobalStyles from 'themes/globalStyles';
import { ThemeProvider } from 'styled-components';
import { theme } from 'themes/theme';
import { AuthProvider } from 'contexts/AuthContext';
import { LoadingProvider } from 'contexts/LoadingContext';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<React.StrictMode>
		<LoadingProvider>
			<AuthProvider>
				<ThemeProvider theme={theme}>
					<GlobalStyles />
					<MainView />
				</ThemeProvider>
			</AuthProvider>
		</LoadingProvider>
	</React.StrictMode>
);
