import React, { createContext, Dispatch, PropsWithChildren, SetStateAction, useState } from 'react';

export interface IAuthContext {
	token: string;
	setAuthToken: (token: string) => void;
}

const AuthContext = createContext<IAuthContext | null>(null);

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
	const [token, setToken] = useState(localStorage.getItem('token') || '');

	const setAuthToken = (token: string) => {
		localStorage.setItem('token', token);
		setToken(token);
	};

	return <AuthContext.Provider value={{ token, setAuthToken }}>{children}</AuthContext.Provider>;
};

export default AuthContext;
