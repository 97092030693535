export const PATHS = {
	INDEX: '/',
	LOGIN: '/login',
	DEVICES: {
		INDEX: '/devices/',
		SUBPAGES: {
			INDEX: '/',
			NEW: 'new',
			EDIT: 'edit',
		},
	},
	TRANSPORTS: {
		INDEX: '/transports/',
		SUBPAGES: {
			INDEX: '/',
			NEW: 'new',
			EDIT: 'edit',
		},
	},
};
