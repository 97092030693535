import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { PATHS } from 'shared/routerPaths';
import AuthContext, { IAuthContext } from 'contexts/AuthContext';

interface IPrivateRoute {
	children: JSX.Element;
}

export const PrivateRoute: React.FC<IPrivateRoute> = ({ children }) => {
	const { token } = useContext(AuthContext) as IAuthContext;

	if (token) {
		return children;
	}

	return <Navigate to={PATHS.LOGIN} />;
};
