import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  html {
    background: #f8f9fa;
  }

  *, *::before, *::after {
    margin: 0;
    padding: 0;
    box-sizing: content-box;
    font-family: "Roboto", sans-serif;
  }
`;
