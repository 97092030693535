export type Theme = typeof theme;

export const theme = {
	colors: {
		primary: '#f5f5ff',
		secondary: '#e0e0e0',
		font: {
			primary: '#fff',
			secondary: '#000',
			error: '#d32f2f',
		},
	},
};
