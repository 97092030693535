import React from 'react';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';
import { Wrapper } from './Chart.styled';
import { CircularProgress, Typography } from '@mui/material';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, zoomPlugin);

interface IChartProps {
	labels: string[];
	dataForChart: any[];
	isLoading: boolean;
	chartError: string;
}

const zoomOptions = {
	pan: {
		enabled: true,
		mode: 'xy',
	},
	zoom: {
		wheel: {
			enabled: true,
		},
		pinch: {
			enabled: true,
		},
		mode: 'xy',
	},
};

export const Chart = ({ labels, dataForChart, isLoading, chartError }: IChartProps) => {
	const options = {
		responsive: true,
		plugins: {
			zoom: zoomOptions,
		},
		datasets: {
			line: {
				borderWidth: 1
			}
		}
	};

	const data = {
		labels,
		datasets: [
			{
				data: dataForChart,
				borderColor: 'rgb(25, 118, 210)',
				backgroundColor: 'rgba(255, 99, 132, 0.5)',
				spanGaps: true,
				pointRadius: 0
			},
		],
	};

	if (isLoading) {
		return <CircularProgress />;
	}

	if (chartError) {
		return <Typography color='error'>{chartError}</Typography>;
	}

	if (dataForChart.length === 0) {
		return <Typography>Brak danych dla wybranego przedziału</Typography>;
	}
	return (
		<Wrapper>
			{/* @ts-ignore */}
			<Line options={options} data={data} />
		</Wrapper>
	);
};
